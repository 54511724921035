
import ErrorMessages from '@/components/Messages.vue'
import FormInputDate from '@/components/FormInputDate.vue'
import FormInputPersonalIdentityNumber from '@/components/FormInputPersonalIdentityNumber.vue'
import FormInputPhoneNumber from '@/components/FormInputPhoneNumber.vue'
import FormInputPostalCode from '@/components/FormInputPostalCode.vue'
import { CampaignService, EmployeeService } from '@/services'
import { Campaign, EmployeeRelativeRelation, EmploymentForm, NewEmployee } from '@/types'
import { Options, Vue } from 'vue-class-component'
import { FmtUtils } from '@/services'
import { Modal } from 'bootstrap'
import { ModalUtils } from '@/services/modalUtils'

@Options({
  name: 'EditNewEmployeesView',
  components: {
    ErrorMessages,
    FormInputDate,
    FormInputPersonalIdentityNumber,
    FormInputPhoneNumber,
    FormInputPostalCode,
  },
})
export default class EditNewEmployeesView extends Vue {
  loadingNewEmployees = false
  newEmployees: NewEmployee[] = []
  newEmployee: NewEmployee | null = null
  phoneExtension: number | null = null

  loadingCampaigns = false
  campaigns: Campaign[] = []

  loadingEmploymentForms = false
  employmentForms: EmploymentForm[] = []

  loadingRelationTypes = false
  relationTypes: EmployeeRelativeRelation[] = []

  savingNewEmployee = false
  saveNewEmployeeSuccess = false

  deleteNewEmployeeModal: Modal | null = null
  newEmployeeToDelete: NewEmployee | null = null
  deletingNewEmployee = false

  fmt = FmtUtils

  sortBy = 'firstName'
  sortReverse = false

  mounted(): void {
    this.fetchCampaigns().then((campaigns: Campaign[]) => {
      this.campaigns = campaigns
    })

    this.fetchEmploymentForms().then((employmentForms: EmploymentForm[]) => {
      this.employmentForms = employmentForms
    })

    this.fetchNewEmployees().then((newEmployees: NewEmployee[]) => {
      this.newEmployees = newEmployees
    })

    this.fetchRelationTypes().then((relationTypes: EmployeeRelativeRelation[]) => {
      this.relationTypes = relationTypes
    })

    this.deleteNewEmployeeModal = ModalUtils.NewModal('#deleteNewEmployeeModal')
  }

  beforeUpdate(): void {
    if (!this.newEmployee) {
      const newEmployeeId = parseInt(this.$route.params.newEmployeeId as string)
      this.newEmployee = this.newEmployees.find((e) => e.id === newEmployeeId) ?? null
    }
  }

  async refresh(): Promise<void> {
    this.newEmployees = []
    this.newEmployee = null
    this.saveNewEmployeeSuccess = false

    this.newEmployees = await this.fetchNewEmployees()
  }

  selectNewEmployee(newEmployee: NewEmployee): void {
    this.saveNewEmployeeSuccess = false

    const errorHandler = this.$refs.saveNewEmployeeErrorHandler as ErrorMessages
    if (errorHandler) {
      errorHandler.clear()
    }

    this.newEmployee = newEmployee
    this.$router.replace({ name: 'EditNewEmployees', params: { newEmployeeId: newEmployee.id } })
  }

  async fetchNewEmployees(): Promise<NewEmployee[]> {
    try {
      this.loadingNewEmployees = true
      return await EmployeeService.fetchNewEmployees(true, true)
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as ErrorMessages).pushErr(err)
      return []
    } finally {
      this.loadingNewEmployees = false
    }
  }

  async fetchEmploymentForms(): Promise<EmploymentForm[]> {
    try {
      this.loadingEmploymentForms = true
      return await EmployeeService.fetchEmploymentForms()
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as ErrorMessages).pushErr(err)
      return []
    } finally {
      this.loadingEmploymentForms = false
    }
  }

  async fetchCampaigns(): Promise<Campaign[]> {
    try {
      this.loadingCampaigns = true
      return await CampaignService.fetchCampaigns()
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as ErrorMessages).pushErr(err)
      return []
    } finally {
      this.loadingCampaigns = false
    }
  }

  async fetchRelationTypes(): Promise<EmployeeRelativeRelation[]> {
    try {
      this.loadingRelationTypes = true
      return await EmployeeService.fetchEmployeeRelativeRelations()
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as ErrorMessages).pushErr(err)
      return []
    } finally {
      this.loadingRelationTypes = false
    }
  }

  async updateNewEmployee(): Promise<void> {
    if (this.newEmployee == null) {
      return
    }

    const msgs = this.$refs.saveNewEmployeeErrorHandler as ErrorMessages
    msgs.clear()

    try {
      this.savingNewEmployee = true
      this.saveNewEmployeeSuccess = false

      const payload = {
        firstName: this.newEmployee.firstName,
        lastName: this.newEmployee.lastName,

        personalIdentityNumber: this.newEmployee.personalIdentityNumber.replace(/[^0-9]/g, ''),

        address: this.newEmployee.address,
        address2: this.newEmployee.address2,
        postalCode: this.newEmployee.postalCode.replace(/[^0-9]/g, ''),
        city: this.newEmployee.city,

        phoneHome: this.newEmployee.phoneHome.replace(/[^0-9]/g, ''),
        phoneMobile: this.newEmployee.phoneMobile.replace(/[^0-9]/g, ''),

        relativeName: this.newEmployee.relativeName,
        relativePhone: this.newEmployee.relativePhone.replace(/[^0-9]/g, ''),
        relativeRelationId: this.newEmployee.relativeRelation?.id,

        bankClearing: this.newEmployee.bankClearing.replace(/[^0-9]/g, ''),
        bankAccount: this.newEmployee.bankAccount.replace(/[^0-9]/g, ''),

        info: this.newEmployee.info,

        employmentDate: this.newEmployee.employmentDate?.toISOString().substring(0, 10),
        employmentFormId: this.newEmployee.employmentForm?.id,

        campaignId: this.newEmployee.campaign?.id,
      }

      await EmployeeService.updateNewEmployee(this.newEmployee.id, payload)

      this.saveNewEmployeeSuccess = true
    } catch (err) {
      msgs.pushErr(err)
    } finally {
      this.savingNewEmployee = false
    }
  }

  showDeleteNewEmployeeModal(newEmployee: NewEmployee | null): void {
    if (!newEmployee) {
      return
    }

    this.newEmployeeToDelete = newEmployee
    this.deleteNewEmployeeModal?.show()
  }

  async deleteNewEmployee(newEmployee: NewEmployee | null): Promise<void> {
    if (!newEmployee) {
      return
    }

    const msgs = this.$refs.deleteNewEmployeeErrorHandler as ErrorMessages

    try {
      this.deletingNewEmployee = true
      msgs.clear()

      await EmployeeService.deleteNewEmployee(newEmployee)

      msgs.pushInfo('OK')
      setTimeout(async () => {
        this.deleteNewEmployeeModal?.hide()
        await this.refresh()
        this.$router.replace({ name: 'EditNewEmployees' })
      }, 1000)
    } catch (err) {
      msgs.pushErr(err)
    } finally {
      this.deletingNewEmployee = false
    }
  }

  sort(columnName: string): void {
    if (columnName === this.sortBy) {
      this.sortReverse = !this.sortReverse
    } else {
      this.sortBy = columnName
      this.sortReverse = false
    }

    this.newEmployees = this.newEmployees.sort((a: NewEmployee, b: NewEmployee) => {
      const modifier = this.sortReverse ? -1 : 1

      switch (this.sortBy) {
        case 'firstName':
          return a.firstName.localeCompare(b.firstName, 'sv-SE') * modifier
        case 'lastName':
          return a.lastName.localeCompare(b.lastName, 'sv-SE') * modifier
        case 'campaignName':
          return a.campaign.abbreviation.localeCompare(b.campaign.abbreviation, 'sv-SE') * modifier
      }

      return 0
    })
  }
}
