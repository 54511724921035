import { Modal } from 'bootstrap'

export type ModalCallback = () => void

export class ModalUtils {
  public static NewModal(
    selector: string,
    onShow: ModalCallback | undefined = undefined,
    onShown: ModalCallback | undefined = undefined
  ): Modal | null {
    const el = document.querySelector(selector)
    if (!el) {
      return null
    }

    const modal = new Modal(el)

    if (onShow) {
      el.addEventListener('show.bs.modal', onShow)
    }

    if (onShown) {
      el.addEventListener('shown.bs.modal', onShown)
    } else {
      el.addEventListener('shown.bs.modal', () => {
        const inputEl = el.querySelector('input,textarea,select') as HTMLInputElement | null
        if (inputEl) {
          inputEl.focus()
        }
      })
    }

    return modal
  }
}
